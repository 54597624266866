import { __assign } from "tslib";
import "./index.scss";
import { defineComponent, reactive, ref } from "vue";
import BasisGroup from "@/views/basisGroup/index.vue";
import Nav from "@/components/nav/nav.vue";
import HomeJumbotron from "@/views/home/homeJumbotron/index.vue";
export default defineComponent({
    components: {
        BasisGroup: BasisGroup,
        Nav: Nav,
        HomeJumbotron: HomeJumbotron
    },
    setup: function () {
        document.documentElement.scrollTop = 0;
        var navActiveIndex = ref("3-1");
        var imgSrcList = reactive({
            imgItems: [],
        });
        return __assign(__assign({}, imgSrcList), { navActiveIndex: navActiveIndex });
    },
});
